.send_request_loading {
  top: 0;
  display: flex;
  flex-direction: column;
  right: 0;
  left: 250px;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  opacity: 0.9;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #000000;

  @media (max-width: 767.98px) {
    left: 0;
  }

  p {
    margin-left: 7px;
  }
}

.send_request_loading_no {
  display: none;
}

.custom-scroll-news{
  /* Scrollbar */
  transition: 1s;
  &::-webkit-scrollbar {
    width: .45rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(27, 27, 27, 0);
    border-radius: 3px;
    transition: 1s;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(27, 27, 27, .25);
  }
  &::-webkit-scrollbar-track{
    background: transparent;
  }
}