.fullScreen {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 10000;
  left: 0;
  right: 0;
  top: 0;
}

.normal {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
}

.menu:hover {
  color: var(--vz-primary);
}

@font-face {
  font-family: "Ratanegra-Cyrillic";
  src: url("../../assetsGame/Ratanegra Cyrillic/Ratanegra-Cyrillic.otf");
}

.button_tik:hover {
  opacity: 0.8;
}

.button_tik:active {
  transform: scale(0.95);
}
