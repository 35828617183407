.custom-resizable-box .react-resizable-handle {
  position: absolute;
  width: 40px; /* Увеличиваем размер ручек */
  height: 40px; /* Увеличиваем размер ручек */
  font-size: 20px;
  bottom: 0;
  right: 0;
  font-weight: bold;
  z-index: 200;
  background-image: url("../../../assetsGame/icons/ugol.png");
}

.react-resizable-handle-nw {
  transform: rotate(0deg);
  background-position: top left;
  z-index: 200;
}

.react-resizable-handle-n {
  transform: rotate(45deg);
  background-position: top left;
  z-index: 200;
}

.react-resizable-handle-w {
  transform: rotate(-45deg);
  background-position: top left;
  cursor: w-resize;
  z-index: 200;
}

.react-resizable-handle-s {
  transform: rotate(-135deg);
  background-position: top left;
  z-index: 200;
}

.react-resizable-handle-e {
  transform: rotate(135deg);
  background-position: top left;
  cursor: w-resize;
  z-index: 200;
}

.scroll-container-category {
  overflow-x: auto;
  white-space: nowrap;
}

/* Кастомизация скроллбара */
.scroll-container-category::-webkit-scrollbar {
  height: 8px; /* Высота горизонтального скроллбара */
}

.scroll-container-category::-webkit-scrollbar-thumb {
  background-color: var(--vz-primary); /* Цвет ползунка */
  border-radius: 10px; /* Закругление краев */
}

.scroll-container-category::-webkit-scrollbar-track {
  background-color: #e9ecef; /* Цвет фона трека */
}

.select_shot{
  border: 1px solid transparent;
}

.select_shot:hover {
  border: 1px solid black;
}

.containerGame {
  display: grid;
  grid-auto-flow: row;
  gap: 0.5vw;
  align-items: center;
  justify-content: center;
}