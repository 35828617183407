@import "widgets";

.settings-image {
  position: absolute;
  right: 2rem;
}

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px
}

.graphLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-post {
    width: 220px;
    height: 200px;
    display: flex;
    align-items: end;
  }

  &-avatar {
    float: left;
    width: 52px;
    height: 100px;
    background-color: #ccc;
    border-radius: 25%;
    margin: 8px;

    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear
  }

  &-line {
    float: left;
    width: 300px;
    height: 16px;
    margin-top: 12px;
    margin-bottom: 10px;
    border-radius: 7px;

    @include background-gradient;
    animation: shine-lines $animation-duration infinite linear
  }

  &-avatar + &-line {
    margin-top: 11px;
    width: 100px
  }

  &-line ~ &-line {
    background-color: #ddd;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px
  }

  40%, 100% {
    background-position: 140px
  }
}


@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset
  }
  40%, 100% {
    background-position: 140px + $avatar-offset
  }
}


