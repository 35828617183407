.accessibility-panel {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.accessibility-hide-images img, .accessibility-hide-images profile-user {
  display: none !important;
}

.grayscale-mode {
  background-color: white  !important;
  color: black !important;
}

.grayscale-mode * {
  color: black !important;
  background-color: white !important;
  border-color: gray !important;
}

.grayscale-mode .hamburger-icon span {
  background-color: black !important;
}

.grayscale-mode img, .grayscale-mode .apex-charts {
  filter: grayscale(100%);
}

.modal-content,
.modal-header,
.modal-body,
.modal-footer {
  background-color: white;
  color: black !important;
  filter: none !important;
}

.modal-backdrop {
  //background-color: black !important;
  opacity: 0.75 !important;
}

.btn {
  font-size: var(--card-title-font-size);
  letter-spacing: var(--shepherd-text-letter-spacing);
}

.accessibility_size_table_10{
  font-size: var(--table-font-size, 11px);
}

.rdt_Table,
.accessibility_size_table,
.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
  font-size: var(--table-font-size, 13px);
}

.rdt_Pagination {
  font-size: var(--table-font-size, 13px) !important;
}

.card-title {
  font-size: var(--card-title-font-size, 16px);
  letter-spacing: var(--shepherd-text-letter-spacing);
}

.card_header_size_text{
  font-size: var(--card-title-font-size, 18px);
}

.calendar_card_size_text{
  font-size: var(--calendar-font-size, 24px);
}

.modal-title {
  font-size: var(--card-title-font-size);
}

.dash-filter-picker.flatpickr-input {
  font-size: var(--card-title-font-size);
  letter-spacing: var(--shepherd-text-letter-spacing);
}

.flatpickr-calendar {
  font-size: var(--card-title-font-size);
  letter-spacing: var(--shepherd-text-letter-spacing);
}

.form-select, .form-control{
  font-size: var(--card-title-font-size, 13px);
  letter-spacing: var(--shepherd-text-letter-spacing);
}

.apex-charts {
  font-size: var(--card-title-font-size);
}